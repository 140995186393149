var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { local: _vm.LOCAL },
      attrs: {
        id: _vm.PROJECT_NAME,
        "data-brand": _vm.BRAND,
        "data-market": _vm.MARKET,
        "data-development": _vm.DEVELOPMENT,
        "data-production": _vm.PRODUCTION,
        "data-vertical": _vm.VERTICAL,
        "data-build-name": _vm.BUILD_NAME,
        "data-build-date": _vm.BUILD_DATE,
        "data-build-hash": _vm.BUILD_HASH,
      },
    },
    [
      _c(
        "lazy-hydrate",
        { attrs: { never: "" } },
        [_c("page-meta", { key: "page-meta" })],
        1
      ),
      _vm._v(" "),
      _vm.showNav
        ? _c(
            "lazy-hydrate",
            { attrs: { never: "" } },
            [
              _c("fragment", {
                key: "header",
                attrs: {
                  "custom-query": _vm.fragmentCustomQuery,
                  name: "header",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("lazy-hydrate", { attrs: { "when-idle": "" } }, [
        _c(
          "section",
          {
            class: [
              "cl-form",
              {
                "cl-form--with-nav": _vm.showNav,
                "cl-form--with-background": _vm.showBackground,
                "cl-form--loaded": _vm.isLoaded,
              },
            ],
          },
          [_c("routes", { key: "route", on: { loaded: _vm.routeLoaded } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("lazy-hydrate", { attrs: { "when-idle": "" } }, [
        _c(
          "div",
          [
            _vm.showChat
              ? _c("zg-chat", {
                  attrs: {
                    brand: _vm.BRAND,
                    country: _vm.MARKET,
                    type: "zopim",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("event-listener"),
            _vm._v(" "),
            !_vm.MORTGAGE ? _c("authentication-listener") : _vm._e(),
            _vm._v(" "),
            !_vm.MORTGAGE ? _c("refresh-tokens-listener") : _vm._e(),
            _vm._v(" "),
            _c("loader"),
            _vm._v(" "),
            _c("modal"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showNav
        ? _c(
            "lazy-hydrate",
            { attrs: { never: "" } },
            [
              _c("fragment", {
                key: "footer",
                attrs: {
                  "custom-query": _vm.fragmentCustomQuery,
                  name: "footer",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.BROKER
        ? _c("lazy-hydrate", { attrs: { never: "" } }, [_c("broker-theme")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }