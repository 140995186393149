import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import requests from '../../requests'

/**
 * Application - Vuex
 */
export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    active: false,
    email: {
      visible: false,
      values: []
    }
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getVerify: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async verifyEmail ({ state, commit, dispatch, rootGetters, getters }, { value, touched, valid, action }) {
      if (!rootGetters['features/getFeatures']?.['verify-email']?.enabled) {
        return valid
      }

      const existing = state?.email?.values?.find(item => item.value === value)
      if (existing) {
        await commit('mutateVerify', {
          type: 'email',
          visible: true
        })

        return existing?.valid
      }

      if (
        !touched ||
        !valid ||
        action === 'input'
      ) return valid

      await commit('mutateVerify', {
        active: true
      })

      await dispatch('messages/setStatusMessage', {
        group: 'application',
        name: 'verifyEmail',
        show: true
      }, { root: true })

      let isValid = valid

      try {
        const response = await requests.serviceCommonGateway.verifyEmail(value)
        isValid = !!(response?.email && response?.email === value)
      } catch (e) {
        isValid = e?.errorDetails?.errorCode === 'mailbox_full'
      } finally {
        await commit('mutateVerify', {
          type: 'email',
          active: false,
          visible: !isValid,
          values: [
            ...state.email.values,
            { value, valid: isValid }
          ]
        })

        await dispatch('loader/hideLoader', {}, { root: true })
      }

      return isValid
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async showVerify ({ commit }, { type }) {
      await commit('mutateVerify', {
        type,
        visible: true
      })

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async hideVerify ({ commit }, { type }) {
      await commit('mutateVerify', {
        type,
        visible: false
      })

      return true
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateVerify (state, payload) {
      if (hasProp(payload, 'active')) { Vue.set(state, 'active', payload.active) }
      if (hasProp(payload, 'type') && hasProp(payload, 'visible')) { Vue.set(state[payload.type], 'visible', payload.visible) }
      if (hasProp(payload, 'type') && hasProp(payload, 'values')) { Vue.set(state[payload.type], 'values', payload.values) }
    }
  }
}
