import { hasProp, isArray, isServer } from '@ocp-zmarta/zmarta-cl'

/**
 * Sets a specific cookie which expires in 30 days.
 * @param {string} name
 * @param {number} [lifeTimeInDays=30]
 * @param {string|number} value
 * @returns {boolean|undefined}
 */
export function setCookie (name, value, lifeTimeInDays) {
  if (getCookie(name)) deleteCookie(name)

  let expires = ''

  if (lifeTimeInDays) {
    const expiresAt = new Date()
    expiresAt.setDate(expiresAt.getDate() + lifeTimeInDays)
    expires = `expires=${expiresAt};`
  }
  document.cookie = `${name}=${value};SameSite=None; ${expires} Secure; Path=/;`
}

/**
 * Returns a specific cookie.
 * @param {string} name
 * @returns {string|null}
 */
export function getCookie (name) {
  if (isServer()) return null

  const value = document.cookie
  const cookies = value.split(';')
  const parsedCookies = {}

  /* istanbul ignore next */
  if (isArray(cookies) && cookies.length >= 1) {
    for (let i = 0; i < cookies.length; i++) {
      const cookieCrumbles = cookies[i].split('=')

      if (cookieCrumbles.length === 2) {
        const key = cookieCrumbles[0].trim()
        const val = cookieCrumbles[1].trim()
        parsedCookies[key] = hasProp(parsedCookies, key) ? parsedCookies[key] : val
      }
    }
  }

  if (hasProp(parsedCookies, name) && parsedCookies[name]) {
    return parsedCookies[name]
  }

  return null
}

/**
 * Deletes a specific cookie.
 * @param {string} name
 */
export function deleteCookie (name) {
  if (!getCookie(name)) return null

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export class Cookie {
  _name
  _value
  _ttl

  constructor (name, value, ttl) {
    // Crete cookie item from exiting cookies
    if (!value) {
      const cValue = getCookie(name)
      this._name = name
      this._value = cValue
      // Create cookie item from new params
    } else {
      this._name = name
      this._value = value
      this._ttl = ttl
    }
  }

  save () {
    setCookie(this._name, this._value, this._ttl)
  }

  delete () {
    deleteCookie(this._name)
  }

  getValue () {
    return this._value
  }

  getName () {
    return this._name
  }
}
