<template>
  <div class="page-meta" />
</template>

<script>
  import { getGABrand, hasProp } from '@ocp-zmarta/zmarta-cl'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PageMeta',
    metaInfo () {
      const brandId = this.getForm?.global?.brandId?.value
      const baseUrl = brandId === this.BRANDS?.ZMARTA?.ID || this.ZMARTA
        ? this.BRANDS?.ZMARTA?.URL
        : this.BRANDS?.BROKER?.URL

      const robots = this.getRoute?.meta?.robots
      const keywords = this.getRoute?.meta?.keywords
      const description = this.getRoute?.meta?.description
      const canonical = this.getRoute?.meta?.canonical || baseUrl?.replace(/\/$/, '')
      const useGtm = (!this.isFragment || this.LOCAL) && this.getRoute?.meta?.gtm !== false

      const preconnectLinks = [
        'https://www.googletagmanager.com'
      ]
      const dnsPrefetchLinks = [
        'https://www.google-analytics.com',
        'https://adservice.google.com',
        'https://8817008.fls.doubleclick.net',
        'https://d11t1ygel9knij.cloudfront.net'
      ]

      const scripts = []
      const noscripts = []
      const disableSanitizers = {}

      if (useGtm) {
        let { pageName, pageType, level } = this.getRoute?.meta?.ga || {}
        pageName = pageName?.replace('{brand}', getGABrand({ brand: this.BRAND, market: this.MARKET }))

        const initialPageView = pageName && {
          pageName,
          cg1: level,
          cg2: this.GA.PRODUCT_FAMILY,
          cg4: this.GA.PRODUCT,
          cg5: pageType,
          uid: this.getRoute?.cookies?.access_uuid || ''
        }

        const zga = this.ZGA.init(initialPageView)

        Object.assign(scripts, (zga.vueMeta?.scripts || []))
        Object.assign(noscripts, (zga.vueMeta?.noscripts || []))
        Object.assign(disableSanitizers, (zga.vueMeta?.disableSanitizers || {}))
      }

      return {
        htmlAttrs: {
          lang: this.LANG
        },
        ...(this.LOCAL || !this.isFragment || this.isSitespeed
          ? { title: this.getRoute?.meta?.title || '' }
          : {}
        ),
        meta: [
          ...(this.LOCAL || !this.isFragment || this.isSitespeed
            ? [
              { charset: 'utf-8', once: true },
              { name: 'viewport', content: 'width=device-width, initial-scale=1', once: true }
            ]
            : []
          ),
          ...(robots && !this.isFragment
            ? [{ name: 'robots', content: robots, once: true }]
            : []
          ),
          ...(keywords && !this.isFragment
            ? [{ name: 'keywords', content: keywords, once: true }]
            : []
          ),
          ...(description && !this.isFragment
            ? [{ name: 'description', content: description, once: true }]
            : []
          )
        ],
        link: [
          ...(!this.isFragment || this.isSitespeed
            ? [
              { rel: 'canonical', href: canonical, once: true },
              ...(preconnectLinks.map(link => ({
                rel: 'preconnect', href: link, once: true
              }))),
              ...(dnsPrefetchLinks.map(link => ({
                rel: 'dns-prefetch', href: link, once: true
              })))
            ]
            : []
          )
        ],
        script: scripts,
        noscript: noscripts,
        __dangerouslyDisableSanitizersByTagID: disableSanitizers
      }
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      ...mapGetters('form', ['getForm']),
      isFragment () {
        return this.getRoute?.meta?.fragment === true
      },
      isSitespeed () {
        return hasProp(this.getRoute?.query, 'sitespeed')
      }
    }
  }
</script>
