import { request } from '@ocp-zmarta/zmarta-cl'
import { serviceAuthHeaders } from '../../../utils'

/**
 * @param {string} ssn
 * @param {string|undefined} phone
 * @param {string|undefined} signMessage
 * @param {number|undefined} eidProvider
 * @param {string} market
 * @param {string|undefined} vertical
 * @returns {Promise<any>}
 */
export async function initiate ({ ssn, phone, signMessage, eidProvider, market, vertical }) {
  return request({
    method: 'POST',
    url: '/service/auth/api/v1/eid/initiate',
    headers: {
      ...serviceAuthHeaders(market, vertical),
      ...eidProvider ? { 'x-auth-eid-provider': eidProvider } : {}
    },
    body: {
      fetchDoesntLikeAnEmptyBodyForSomeReason: true,
      pno: ssn,
      phone,
      signMessage
    }
  })
}
