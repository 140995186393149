import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient, from, HttpLink, Observable } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import Vue from 'vue'
import { refreshToken } from '../utils'

const notAuthorized = () => {
  Vue.prototype.EVENT_BUS.$emit('authentication:not-authorized')
}

const apiError = (errorName, error) => {
  Vue.prototype.EVENT_BUS.$emit('api:error', { errorName, error })
}

// ? ####################
// ? Error handling
// ? ####################

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    const error = graphQLErrors[0]
    const errorName = error?.name ?? error?.extensions?.exception?.name
    const errorCode = error?.data?.errorCode ?? error?.extensions?.exception?.errorCode

    if (errorName === 'AUTHORIZATION_ERROR' && errorCode === 'JWT_EXPIRED') {
      return new Observable(observer => {
        refreshToken({ market: Vue.prototype.MARKET })
          .then(() => {
            Vue.prototype.EVENT_BUS.$emit('refresh-tokens:start-poll')

            forward(operation)
              .subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              })
          })
          .catch(() => {
            Vue.prototype.EVENT_BUS.$emit('refresh-tokens:stop-poll')

            notAuthorized()
            observer.error(graphQLErrors)
          })
      })
    } else if (errorName === 'AUTHORIZATION_ERROR') {
      notAuthorized()
    } else {
      apiError(errorName, error)
    }
  }
})

// ? ####################
// ? HttpLink
// ? ####################

const claHttpLink = new HttpLink({
  fetch,
  uri: '/service/cla/graphql',
  credentials: 'include'
})

const mortgageGatewayHttpLink = new HttpLink({ // kan ev behövas egen fetchPolicy
  fetch,
  uri: '/service/mortgage-gateway/graphql',
  credentials: 'include',
  headers: {
    market: 'se'
  }
})

const smeApiHttpLink = new HttpLink({
  fetch,
  uri: '/service/sme/graphql',
  credentials: 'include'
})

// ? ####################
// ? Clients
// ? ####################

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'network-only'
  },
  mutate: {
    fetchPolicy: 'network-only'
  }
}

export const claGraphqlClient = new ApolloClient({
  link: from([errorLink, claHttpLink]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions: defaultOptions
})

export const mortgageGatewayClient = new ApolloClient({
  link: from([errorLink, mortgageGatewayHttpLink]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions: defaultOptions
})

export const smeGraphqlClient = new ApolloClient({
  link: from([errorLink, smeApiHttpLink]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions: defaultOptions
})

export default claGraphqlClient
