import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function prepareFinalizeApplication ({ fields = {}, form = {} } = {}) {
  form.global = form?.global || {}
  form.global.appNo = fields?.global?.appNo?.value

  switch (fields?.global?.leadType?.value) {
    case 'continueApplication':
      form.global.mediaAffId = CONSTANTS.MEDIA_AFF_ID.FULL_LEAD
      break
    case 'addCoApplicant':
      form.global.mediaAffId = CONSTANTS.MEDIA_AFF_ID.FULL_ADD_CO
      break
    default:
      form.global.mediaAffId = CONSTANTS.MEDIA_AFF_ID.FULL
      break
  }

  // Remove whiteLabel
  if (form?.whiteLabel) delete form.whiteLabel

  return form
}
