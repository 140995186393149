export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    vertical: null,
    brand: null,
    market: null,
    route: null,
    routes: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getRoute: state => state.route,
    getRoutes: state => state.routes,
    getQuery: state => state.route?.query,
    getReferer: state => state.route?.referer
  }
}
